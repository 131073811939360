<template>
  <section class="pt-0">
    <div v-if="!is_loading">
      <h2>Edit Property #{{ property.uniqid }}</h2>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="basic-tab"
            data-bs-toggle="tab"
            data-bs-target="#basic"
            type="button"
            role="tab"
            aria-controls="basic"
            aria-selected="false"
          >
            Basic Information
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="images-tab"
            data-bs-toggle="tab"
            data-bs-target="#images"
            type="button"
            role="tab"
            aria-controls="images"
            aria-selected="true"
          >
            Images
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="details-tab"
            data-bs-toggle="tab"
            data-bs-target="#details"
            type="button"
            role="tab"
            aria-controls="details"
            aria-selected="true"
          >
            Details
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="contacts-tab"
            data-bs-toggle="tab"
            data-bs-target="#contacts"
            type="button"
            role="tab"
            aria-controls="contacts"
            aria-selected="true"
          >
            Contacts
          </button>
        </li>
      </ul>
      <div class="tab-content bg-white" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="basic"
          role="tabpanel"
          aria-labelledby="basic-tab"
        >
          <basic-information :property="property" />
        </div>
        <div
          class="tab-pane fade"
          id="images"
          role="tabpanel"
          aria-labelledby="images-tab"
        >
          <property-images :the_images="images" :property="property" />
        </div>
        <div
          class="tab-pane fade"
          id="details"
          role="tabpanel"
          aria-labelledby="details-tab"
        >
          <property-details :property="property" :amenities="amenities" :details="details" />
        </div>
        <div
          class="tab-pane fade"
          id="contacts"
          role="tabpanel"
          aria-labelledby="contacts-tab"
        >
          <property-contacts :property="property" :agencies="agencies" />
        </div>
      </div>
    </div>

    <loading v-if="is_loading" />
  </section>
</template>

<script>
import BasicInformation from './components/edit/BasicInformation.vue'
import PropertyImages from './components/edit/PropertyImages.vue'
import PropertyDetails from './components/edit/PropertyDetails.vue'
import PropertyContacts from './components/edit/PropertyContacts.vue'

export default {
  components: {
    BasicInformation,
    PropertyImages,
    PropertyDetails,
    PropertyContacts
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      property: {},
      images: [],
      details: [],
      amenities: [],
      agencies: [],
      categories: [],
      regions: [],
      is_loading: true,
      errors: {},
      selected_category: null,
      selected_region: null,
      change_category: false,
      change_region: false,
      show_add_images: false,
      loading_images: true
    };
  },

  methods: {
    fetch() {
      this.is_loading = true;
      this.loading_images = true

      this.$axios
        .get(`/api/v1/admin/properties/${this.$route.params.id}/edit`)
        .then((response) => {
          this.property = response.data.property;
          this.images = response.data.images;
          this.amenities = response.data.amenities;
          this.details = response.data.details;
          this.agencies = response.data.agencies;
          this.categories = response.data.categories;
          this.regions = response.data.regions;
          this.is_loading = false;
          this.loading_images = false
        });
    },

    updateInfo() {
      let data = {
        property_id: this.property.id,
        name: this.property.name,
        type: this.property.type,
        amount: this.property.amount,
        description: this.property.description
      }

      if(this.property.type == 'rent') {
        data.payment_frequency = this.property.payment_frequency
      }

      if(this.selected_category != null) {
        data.category_id = this.selected_category.id
      }

      if(this.selected_region != null) {
        data.region_id = this.selected_region.id
      }

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/info`, data).then(response => {
        this.$router.push({ name: 'page', params: { slug: response.data.slug } })
      })
    },

    uploadImages() {
      this.loading_images = true
      let data = new FormData
      data.append('property_id', this.property.id)

      Object.values(this.$refs.upload_images.files).forEach((file, i) => {
        data.append(`images[${i}]`, file)
      })

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/upload-images`, data).then(response => {
        this.images = response.data.images
        this.show_add_images = false
        this.loading_images = false
      })
    },

    makeMain(image) {
      this.loading_images = true

      let data = {
        property_id: this.property.id,
        image_id: image.id
      }

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/make-main`, data).then(response => {
        this.images = response.data.images
        this.loading_images = false
      })
    },

    deleteImage(image) {
      this.loading_images = true

      let data = {
        property_id: this.property.id,
        image_id: image.id
      }

      this.$axios.post(`/api/v1/my-listings/${this.$route.params.slug}/edit/delete-image`, data).then(response => {
        this.images = response.data.images
        this.loading_images = false
      })
    }
  },
};
</script>
